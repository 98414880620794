
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const RequestPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Request in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.REQUEST_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.REQUEST_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'requests',
        object_type: null,
        group: {
            label: 'Work',
            identifier: 'work',
            order: 3
        },
        sub_group: {
            label: 'Requests',
            identifier: 'requests',
            order: 2
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'requests',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [ 
                        {
                            label: "Subject",
                            identifier: "requests.subject",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Account",
                            identifier: "requests.account",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "requests.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Status",
                            identifier: "requests.status",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Description",
                            identifier: "requests.description",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }                           
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'requests',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    sections: [
                        // General
                        {
                            label: "Request Information",
                            id: 'request_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Primary Contact",
                                    identifier: "requests.primary_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Property",
                                    identifier: "requests.property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Priority",
                                    identifier: "requests.priority",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Source",
                                    identifier: "requests.source",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Zone",
                                    identifier: "requests.zone",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tags",
                                    identifier: "requests.tags",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Owner",
                                    identifier: "requests.owner",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // Work Information
                        {
                            label: "Work Information",
                            id: 'work_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Instructions",
                                    identifier: "requests.instructions",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Client Instructions",
                                    identifier: "requests.client_instructions",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Assignment Status",
                                    identifier: "requests.assignment_status",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Recommended Crew Size",
                                    identifier: "requests.recommended_crew_size",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Minimum Crew Size",
                                    identifier: "requests.minimum_crew_size",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                // {
                                //     label: "Skills Required",
                                //     identifier: "requests.skills_required",
                                //     is_required: false,
                                //     is_read_only: false,
                                //     is_shown_if_empty: true
                                // },
                                // {
                                //     label: "Tools Required",
                                //     identifier: "requests.tools_required",
                                //     is_required: false,
                                //     is_read_only: false,
                                //     is_shown_if_empty: true
                                // }
                            ],
                        },
                        // Schedule Information
                        {
                            label: "Schedule Information",
                            id: 'schedule_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Schedule Status",
                                    identifier: "requests.schedule_status",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Completed Date",
                                    identifier: "requests.completed_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: false
                                },
                                {
                                    label: "Closed Date",
                                    identifier: "requests.closed_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: false
                                },
                                {
                                    label: "Converted Date",
                                    identifier: "requests.converted_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: false
                                },
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [ 
                                {
                                    label: "Popup Alert",
                                    identifier: "requests.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Request ID",
                                    identifier: "requests.request_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "requests.created_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "requests.created_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated At",
                                    identifier: "requests.last_updated_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated By",
                                    identifier: "requests.last_updated_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Parent",
                                    identifier: "requests.parent",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: false
                                },
                                
                            ],
                        },
                    ]
                }
            },
            component_4: {
                id: "component_4",
                label: 'Related',
                object_identifier: 'requests',
                connection_type: 'none',
                type: ComponentTypes.RELATED,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    related: [
                        // Visits
                        {
                            label: 'Visits',
                            identifier: 'related_visits',
                            object_identifier: 'visits',
                            display_field: 'visits.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["visits.related_to"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Related To',
                                            input: 'parent.id',
                                            data: 'parent.id',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "visits.last_updated_at",
                            sort_by: ["visits.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Title',
                                    identifier: 'visits.title',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Start',
                                    identifier: 'visits.start_date',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Type',
                                    identifier: 'visits.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Status',
                                    identifier: 'visits.status',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        },
                        // Tasks
                        {
                            label: 'Tasks',
                            identifier: 'related_tasks',
                            object_identifier: 'tasks',
                            display_field: 'tasks.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["tasks.request"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Related Request',
                                            input: 'parent.id',
                                            data: 'parent.id',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "tasks.last_updated_at",
                            sort_by: ["tasks.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'tasks.subject',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'tasks.due_date',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Type',
                                    identifier: 'tasks.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Status',
                                    identifier: 'tasks.status',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        },
                        // Estimates
                        {
                            label: 'Estimates',
                            identifier: 'related_estimates',
                            object_identifier: 'estimates',
                            display_field: 'estimates.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["estimates.request"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Related Request',
                                            input: 'parent.id',
                                            data: 'parent.id',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "estimates.last_updated_at",
                            sort_by: ["estimates.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'estimates.subject',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'estimates.due_date',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Type',
                                    identifier: 'estimates.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Status',
                                    identifier: 'estimates.status',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        },
                        // Jobs
                        {
                            label: 'Jobs',
                            identifier: 'related_jobs',
                            object_identifier: 'jobs',
                            display_field: 'jobs.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["jobs.request"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Related Request',
                                            input: 'parent.request',
                                            data: 'parent.request',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                },
                                {
                                    fields: ["jobs.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "jobs.last_updated_at",
                            sort_by: ["jobs.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'jobs.subject',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Type',
                                    identifier: 'jobs.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        },
                        // Invoices
                        {
                            label: 'Invoices',
                            identifier: 'related_invoices',
                            object_identifier: 'invoices',
                            display_field: 'invoices.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["invoices.request"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Related Request',
                                            input: '$parent.request',
                                            data: '$parent.request',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                },
                                {
                                    fields: ["invoices.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "invoices.last_updated_at",
                            sort_by: ["invoices.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'invoices.subject',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Type',
                                    identifier: 'invoices.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        },
                    ]
                }
            },
            component_5: {
                id: "component_5",
                label: 'Activity',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.ACTIVITY,
                special_type: null,
                header_type: 'normal',
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    activities: [
                        // Tasks
                        {
                            label: 'Tasks',
                            identifier: 'tasks_0',
                            object_identifier: 'tasks',
                            display_field: 'tasks.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["tasks.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "tasks.last_updated_at",
                            sort_by: ["tasks.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "tasks.edit",
                                "tasks.mark_completed",
                                "tasks.mark_closed",
                                "tasks.convert_to_job",
                                "tasks.archive",
                                "tasks.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'tasks.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'tasks.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'tasks.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'tasks.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'tasks.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'tasks.due_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.due_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'tasks.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Visits
                        {
                            label: 'Visits',
                            identifier: 'visits_2',
                            object_identifier: 'visits',
                            display_field: 'visits.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["visits.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "visits.last_updated_at",
                            sort_by: ["visits.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "visits.edit",
                                "visits.mark_completed",
                                "visits.mark_closed",
                                "visits.convert_to_invoice",
                                "visits.archive",
                                "visits.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'visits.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'visits.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'visits.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'visits.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'visits.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'End Date',
                                    identifier: 'visits.end_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.end_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Duration',
                                    identifier: 'visits.duration',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.duration"],
                                    display_format: null
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'visits.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Address',
                                    identifier: 'visits.address',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.address", "properties.address", "address.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Notes
                        {
                            label: 'Notes',
                            identifier: 'notes_3',
                            object_identifier: 'notes',
                            display_field: 'notes.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["notes.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "notes.created_at",
                            sort_by: ["notes.created_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [],
                            fields: [
                                {
                                    label: 'Title',
                                    identifier: 'notes.title',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.title"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'notes.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.status","dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Created At',
                                    identifier: 'notes.created_at',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_at"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Created By',
                                    identifier: 'notes.created_by',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_by","users.name","name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Body',
                                    identifier: 'notes.body',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.body"],
                                    display_format: null
                                }
                            ]
                        }
                    ]
                }
            },
            component_6: {
                id: "component_6",
                label: 'Schedule',
                object_identifier: 'requests',
                connection_type: 'parent',
                type: ComponentTypes.SCHEDULE,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    views: ["all_visits"]
                }
            },
            component_7: {
                id: "component_7",
                label: 'Timeline',
                object_identifier: 'requests',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {
                        contacts: true,
                        properties: true,
                        tasks: true,
                        events: true,
                        notes: true,
                        attachments: true
                    }
                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'details_schedule_related',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_2", "component_4" ], ["component_5", "component_6", "component_7"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_2", "component_4"] }, { id: '3', flexSize: 1, components: [["component_5", "component_6", "component_7"]] }],
                    desktop: [{ id: '2', flexSize: 2, components: ["component_2", "component_4"] }, { id: '3', flexSize: 1, components: [["component_5", "component_6", "component_7"]] }]
                }
            }
        ],
        item_actions: [
            "requests.start",
            "requests.complete",
            "requests.close",
            "requests.convert_to_task",
            "requests.convert_to_estimate",
            "requests.convert_to_job",
            "requests.convert_to_invoice",
            "requests.archive"
        ],
        more_actions: [ 
            "requests.duplicate",
            "requests.delete"
        ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default RequestPageLayoutsData;