

const StandardPermissionsData = {
    admin_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: true },
            'edit_company_branding': { use: true },
            'edit_financial_information': { use: true },
            'primary_company_contact': { use: true },
            'customizations_manager': { use: true },
            'customize_objects': { use: true },
            'customize_list_views': { use: true },
            'customize_page_layouts': { use: true },
            'customize_search_views': { use: true },
            'manage_prohelper_subscription': { use: true },
            'manage_internal_users': { use: true },
            'manage_external_users': { use: true },
            'manage_roles_permission_sets': { use: true },
            'manage_integrations': { use: true },
            'view_all_data': { use: true },
            'modifiy_all_data': { use: true },
            'data_importing_and_exporting': { use: true },
            'customizations_importing_and_exporting': { use: true },
            'work_settings': { use: true },
            'schedule_settings': { use: true },
            'customer_settings': { use: true },
            'billing_settings': { use: true },
            'team_settings': { use: true },
            'performance_settings': { use: true },
            'communication_settings': { use: true },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: true },
            'change_type': { use: true },
            'transfer_owner': { use: true },
            'set_popups': { use: true },
            'send_emails': { use: true },
            'batch_email': { use: true },
            'view_encrypted_data': { use: true },
            'manage_archived_data': { use: true },
            'manage_test_data': { use: true }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: true, visible: true },
            'events': { active: true, visible: true },
            'visits': { active: true, visible: true },
            'work': { active: true, visible: true },
            'tasks': { active: true, visible: true },
            'requests': { active: true, visible: true },
            'jobs': { active: true, visible: true },
            'routes': { active: true, visible: true },
            'route_stops': { active: true, visible: true },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: true, visible: true },
            'estimates': { active: true, visible: true },
            'invoices': { active: true, visible: true },
            'subscriptions': { active: true, visible: true },
            'payments': { active: true, visible: true },
            'items': { active: true, visible: true },
            'purchase_orders': { active: true, visible: true },
            'audit': { active: true, visible: true },
            'ledgers': { active: true, visible: true },
            'reports': { active: true, visible: true },
            'team': { active: true, visible: true },
            'users': { active: true, visible: true },
            'vehicles': { active: true, visible: true },
            'security': { active: true, visible: true },
            'roles': { active: true, visible: true },
            'permission_sets': { active: true, visible: true },
            'settings': { active: true, visible: true },
        },
        object_permissions: {
            'events': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'visits': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'requests': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'jobs': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'routes': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'accounts': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'contacts': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'properties': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'estimates': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'invoices': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'subscriptions': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'payments': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'items': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'purchase_orders': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'ledgers': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'reports': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'users': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'vehicles': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'roles': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'permission_sets': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        list_view_permissions: {},
        page_layout_permissions: {}
    },
    manager_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: false },
            'edit_company_branding': { use: false },
            'edit_financial_information': { use: false },
            'primary_company_contact': { use: false },
            'customizations_manager': { use: true },
            'customize_objects': { use: true },
            'customize_list_views': { use: true },
            'customize_page_layouts': { use: true },
            'customize_search_views': { use: true },
            'manage_internal_users': { use: true },
            'manage_external_users': { use: true },
            'manage_roles_permission_sets': { use: false },
            'manage_integrations': { use: false },
            'view_all_data': { use: false },
            'modifiy_all_data': { use: false },
            'data_importing_exporting': { use: false },
            'manage_prohelper_subscription': { use: false },
            'data_importing_and_exporting': { use: true },
            'customizations_importing_and_exporting': { use: true },
            'work_settings': { use: true },
            'schedule_settings': { use: true },
            'customer_settings': { use: true },
            'billing_settings': { use: true },
            'team_settings': { use: true },
            'performance_settings': { use: true },
            'communication_settings': { use: true },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: true },
            'change_type': { use: true },
            'transfer_owner': { use: true },
            'set_popups': { use: true },
            'send_emails': { use: true },
            'batch_email': { use: true },
            'view_encrypted_data': { use: true },
            'manage_archived_data': { use: true },
            'manage_test_data': { use: true }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: true, visible: true },
            'events': { active: true, visible: true },
            'visits': { active: true, visible: true },
            'work': { active: true, visible: true },
            'tasks': { active: true, visible: true },
            'requests': { active: true, visible: true },
            'jobs': { active: true, visible: true },
            'routes': { active: true, visible: true },
            'route_stops': { active: true, visible: true },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: true, visible: true },
            'estimates': { active: true, visible: true },
            'invoices': { active: true, visible: true },
            'subscriptions': { active: true, visible: true },
            'payments': { active: true, visible: true },
            'items': { active: true, visible: true },
            'purchase_orders': { active: true, visible: true },
            'audit': { active: true, visible: true },
            'ledgers': { active: true, visible: true },
            'reports': { active: true, visible: true },
            'team': { active: true, visible: true },
            'users': { active: true, visible: true },
            'vehicles': { active: true, visible: true },
            'security': { active: false, visible: false },
            'roles': { active: false, visible: false },
            'permission_sets': { active: false, visible: false },
            'settings': { active: true, visible: true },
        },
        object_permissions: {
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'requests': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'jobs': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'routes': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'accounts': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'contacts': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'properties': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'estimates': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'invoices': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'subscriptions': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'payments': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'items': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'purchase_orders': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'ledgers': { read: true, create: true, edit: false, delete: false, view_all: true, modify_all: false },
            'reports': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'users': { read: true, create: false, edit: true, delete: false, view_all: true, modify_all: true },
            'vehicles': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'roles': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'permission_sets': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        list_view_permissions: {},
        page_layout_permissions: {}
    },
    dispatcher_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: false },
            'edit_company_branding': { use: false },
            'edit_financial_information': { use: false },
            'primary_company_contact': { use: false },
            'customizations_manager': { use: false },
            'customize_objects': { use: false },
            'customize_list_views': { use: true },
            'customize_page_layouts': { use: true },
            'customize_search_views': { use: true },
            'manage_internal_users': { use: true },
            'manage_external_users': { use: false },
            'manage_roles_permission_sets': { use: false },
            'manage_integrations': { use: false },
            'view_all_data': { use: false },
            'modifiy_all_data': { use: false },
            'data_importing_exporting': { use: false },
            'manage_prohelper_subscription': { use: false },
            'data_importing_and_exporting': { use: false },
            'customizations_importing_and_exporting': { use: false },
            'work_settings': { use: true },
            'schedule_settings': { use: true },
            'customer_settings': { use: true },
            'billing_settings': { use: false },
            'team_settings': { use: true },
            'performance_settings': { use: true },
            'communication_settings': { use: true },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: true },
            'change_type': { use: true },
            'transfer_owner': { use: true },
            'set_popups': { use: true },
            'send_emails': { use: true },
            'batch_email': { use: true },
            'view_encrypted_data': { use: false },
            'manage_archived_data': { use: true },
            'manage_test_data': { use: true }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: true, visible: true },
            'events': { active: true, visible: true },
            'visits': { active: true, visible: true },
            'work': { active: true, visible: true },
            'tasks': { active: true, visible: true },
            'requests': { active: true, visible: true },
            'jobs': { active: true, visible: true },
            'routes': { active: true, visible: true },
            'route_stops': { active: true, visible: true },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: false, visible: false },
            'estimates': { active: true, visible: true },
            'invoices': { active: true, visible: true },
            'subscriptions': { active: true, visible: true },
            'payments': { active: true, visible: true },
            'items': { active: true, visible: true },
            'puchase_orders': { active: false, visible: false },
            'audit': { active: false, visible: false },
            'ledgers': { active: false, visible: false },
            'reports': { active: false, visible: false },
            'team': { active: true, visible: true },
            'users': { active: true, visible: true },
            'vehicles': { active: true, visible: true },
            'security': { active: false, visible: false },
            'roles': { active: false, visible: false },
            'permission_sets': { active: false, visible: false },
            'settings': { active: true, visible: true },
        },
        object_permissions: {
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'requests': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'jobs': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'routes': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'accounts': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'contacts': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'properties': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'estimates': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'invoices': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'subscriptions': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'payments': { read: true, create: true, edit: true, delete: false, view_all: false, modify_all: false },
            'items': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: true },
            'purchase_orders': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'ledgers': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'reports': { read: true, create: true, edit: true, delete: false, view_all: false, modify_all: false },
            'users': { read: true, create: false, edit: true, delete: false, view_all: true, modify_all: true },
            'vehicles': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'roles': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'permission_sets': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        view_permissions: {},
        page_layout_permissions: {}
    },
    field_technician_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: false },
            'edit_company_branding': { use: false },
            'edit_financial_information': { use: false },
            'primary_company_contact': { use: false },
            'customizations_manager': { use: false },
            'customize_objects': { use: false },
            'customize_list_views': { use: false },
            'customize_page_layouts': { use: false },
            'customize_search_views': { use: false },
            'manage_internal_users': { use: false },
            'manage_external_users': { use: false },
            'manage_roles_permission_sets': { use: false },
            'manage_integrations': { use: false },
            'view_all_data': { use: false },
            'modifiy_all_data': { use: false },
            'data_importing_exporting': { use: false },
            'manage_prohelper_subscription': { use: false },
            'data_importing_and_exporting': { use: false },
            'customizations_importing_and_exporting': { use: false },
            'work_settings': { use: false },
            'schedule_settings': { use: false },
            'customer_settings': { use: false },
            'billing_settings': { use: false },
            'team_settings': { use: false },
            'performance_settings': { use: false },
            'communication_settings': { use: false },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: false },
            'change_type': { use: true },
            'transfer_owner': { use: true },
            'set_popups': { use: true },
            'send_emails': { use: false },
            'batch_email': { use: false },
            'view_encrypted_data': { use: false },
            'manage_archived_data': { use: false },
            'manage_test_data': { use: true }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: true, visible: true },
            'events': { active: true, visible: true },
            'visits': { active: true, visible: true },
            'work': { active: true, visible: true },
            'tasks': { active: true, visible: true },
            'requests': { active: true, visible: true },
            'jobs': { active: true, visible: true },
            'routes': { active: true, visible: true },
            'route_stops': { active: true, visible: true },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: false, visible: false },
            'estimates': { active: false, visible: false },
            'invoices': { active: false, visible: false },
            'subscriptions': { active: false, visible: false },
            'payments': { active: false, visible: false },
            'items': { active: true, visible: true },
            'purchase_orders': { active: false, visible: false },
            'audit': { active: false, visible: false },
            'ledgers': { active: false, visible: false },
            'reports': { active: false, visible: false },
            'team': { active: false, visible: false },
            'users': { active: false, visible: false },
            'vehicles': { active: false, visible: false },
            'security': { active: false, visible: false },
            'roles': { active: false, visible: false },
            'permission_sets': { active: false, visible: false },
            'settings': { active: false, visible: false },
        },
        object_permissions: {
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'requests': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'jobs': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'routes': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'accounts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'contacts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'properties': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'estimates': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'invoices': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'subscriptions': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'payments': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'items': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: true },
            'purchase_orders': { read: false, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'ledgers': { read: false, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'reports': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'users': { read: true, create: false, edit: true, delete: false, view_all: true, modify_all: false },
            'vehicles': { read: true, create: false, edit: true, delete: false, view_all: false, modify_all: false },
            'roles': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'permission_sets': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        list_view_permissions: {},
        page_layout_permissions: {}
    },
    service_employee_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: false },
            'edit_company_branding': { use: false },
            'edit_financial_information': { use: false },
            'primary_company_contact': { use: false },
            'customizations_manager': { use: false },
            'customize_objects': { use: false },
            'customize_list_views': { use: false },
            'customize_page_layouts': { use: false },
            'customize_search_views': { use: false },
            'manage_internal_users': { use: false },
            'manage_external_users': { use: false },
            'manage_roles_permission_sets': { use: false },
            'manage_integrations': { use: false },
            'view_all_data': { use: false },
            'modifiy_all_data': { use: false },
            'data_importing_exporting': { use: false },
            'manage_prohelper_subscription': { use: false },
            'data_importing_and_exporting': { use: false },
            'customizations_importing_and_exporting': { use: false },
            'work_settings': { use: false },
            'schedule_settings': { use: false },
            'customer_settings': { use: false },
            'billing_settings': { use: false },
            'team_settings': { use: false },
            'performance_settings': { use: false },
            'communication_settings': { use: false },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: false },
            'change_type': { use: false },
            'transfer_owner': { use: false },
            'set_popups': { use: false },
            'send_emails': { use: false },
            'batch_email': { use: false },
            'view_encrypted_data': { use: false },
            'manage_archived_data': { use: false },
            'manage_test_data': { use: true }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: true, visible: true },
            'events': { active: true, visible: true },
            'visits': { active: true, visible: true },
            'work': { active: true, visible: true },
            'tasks': { active: true, visible: true },
            'requests': { active: true, visible: true },
            'jobs': { active: true, visible: true },
            'routes': { active: true, visible: false },
            'route_stops': { active: true, visible: true },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: false, visible: false },
            'estimates': { active: false, visible: false },
            'invoices': { active: false, visible: false },
            'subscriptions': { active: false, visible: false },
            'payments': { active: false, visible: false },
            'items': { active: true, visible: true },
            'purchase_orders': { active: false, visible: false },
            'audit': { active: false, visible: false },
            'ledgers': { active: false, visible: false },
            'reports': { active: false, visible: false },
            'team': { active: false, visible: false },
            'users': { active: false, visible: false },
            'vehicles': { active: false, visible: false },
            'security': { active: false, visible: false },
            'roles': { active: false, visible: false },
            'permission_sets': { active: false, visible: false },
            'settings': { active: false, visible: false },
        },
        object_permissions: {
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: false },
            'requests': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'jobs': { read: true, create: false, edit: true, delete: false, view_all: true, modify_all: false },
            'routes': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'accounts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'contacts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'properties': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'estimates': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'invoices': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'subscriptions': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'payments': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'items': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'purchase_orders': { read: false, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'ledgers': { read: false, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'reports': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'users': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'vehicles': { read: true, create: false, edit: true, delete: false, view_all: false, modify_all: false },
            'roles': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'permission_sets': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        list_view_permissions: {},
        page_layout_permissions: {}
    },
    accountant_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: false },
            'edit_company_branding': { use: false },
            'edit_financial_information': { use: false },
            'primary_company_contact': { use: false },
            'customizations_manager': { use: false },
            'customize_objects': { use: false },
            'customize_list_views': { use: false },
            'customize_page_layouts': { use: false },
            'customize_search_views': { use: false },
            'manage_internal_users': { use: false },
            'manage_external_users': { use: false },
            'manage_roles_permission_sets': { use: false },
            'manage_integrations': { use: false },
            'view_all_data': { use: false },
            'modifiy_all_data': { use: false },
            'data_importing_exporting': { use: true },
            'manage_prohelper_subscription': { use: false },
            'data_importing_and_exporting': { use: false },
            'customizations_importing_and_exporting': { use: false },
            'work_settings': { use: false },
            'schedule_settings': { use: false },
            'customer_settings': { use: false },
            'billing_settings': { use: false },
            'team_settings': { use: false },
            'performance_settings': { use: false },
            'communication_settings': { use: false },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: true },
            'change_type': { use: true },
            'transfer_owner': { use: true },
            'set_popups': { use: true },
            'send_emails': { use: false },
            'batch_email': { use: false },
            'view_encrypted_data': { use: true },
            'manage_archived_data': { use: true },
            'manage_test_data': { use: true }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: false, visible: false },
            'events': { active: false, visible: false },
            'visits': { active: false, visible: false },
            'work': { active: false, visible: false },
            'tasks': { active: true, visible: true },
            'requests': { active: false, visible: false },
            'jobs': { active: false, visible: false },
            'routes': { active: false, visible: false },
            'route_stops': { active: false, visible: false },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: true, visible: true },
            'estimates': { active: true, visible: true },
            'invoices': { active: true, visible: true },
            'subscriptions': { active: true, visible: true },
            'payments': { active: false, visible: false },
            'items': { active: true, visible: true },
            'purchase_orders': { active: true, visible: true },
            'audit': { active: true, visible: true },
            'ledgers': { active: true, visible: true },
            'reports': { active: true, visible: true },
            'team': { active: false, visible: false },
            'users': { active: false, visible: false },
            'vehicles': { active: false, visible: false },
            'security': { active: false, visible: false },
            'roles': { active: false, visible: false },
            'permission_sets': { active: false, visible: false },
            'settings': { active: false, visible: false },
        },
        object_permissions: {
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: false },
            'requests': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'jobs': { read: true, create: false, edit: true, delete: false, view_all: false, modify_all: false },
            'routes': { read: false, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'accounts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'contacts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'properties': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'estimates': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'invoices': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'subscriptions': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'payments': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'items': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'purchase_orders': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'ledgers': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'reports': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'users': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'vehicles': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'roles': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'permission_sets': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        list_view_permissions: {},
        page_layout_permissions: {}
    },
    viewer_permission_set: {
        platform_permissions: {
            'company_access': { use: true }
        },
        admin_permissions: {
            'edit_company_information': { use: false },
            'edit_company_branding': { use: false },
            'edit_financial_information': { use: false },
            'primary_company_contact': { use: false },
            'customizations_manager': { use: false },
            'customize_objects': { use: false },
            'customize_list_views': { use: false },
            'customize_page_layouts': { use: false },
            'customize_search_views': { use: false },
            'manage_internal_users': { use: false },
            'manage_external_users': { use: false },
            'manage_roles_permission_sets': { use: false },
            'manage_integrations': { use: false },
            'view_all_data': { use: false },
            'modifiy_all_data': { use: false },
            'data_importing_exporting': { use: false },
            'manage_prohelper_subscription': { use: false },
            'data_importing_and_exporting': { use: false },
            'customizations_importing_and_exporting': { use: false },
            'work_settings': { use: false },
            'schedule_settings': { use: false },
            'customer_settings': { use: false },
            'billing_settings': { use: false },
            'team_settings': { use: false },
            'performance_settings': { use: false },
            'communication_settings': { use: false },
        },
        user_permissions: {
            'edit_personal_information': { use: true },
            'perform_batch_actions': { use: false },
            'change_type': { use: false },
            'transfer_owner': { use: false },
            'set_popups': { use: false },
            'send_emails': { use: false },
            'batch_email': { use: false },
            'view_encrypted_data': { use: false },
            'manage_archived_data': { use: false },
            'manage_test_data': { use: false }
        },
        navigation_item_permissions: {
            'home': { active: true, visible: true },
            'schedule': { active: true, visible: true },
            'events': { active: true, visible: true },
            'visits': { active: true, visible: true },
            'work': { active: true, visible: true },
            'tasks': { active: true, visible: true },
            'requests': { active: true, visible: true },
            'jobs': { active: true, visible: true },
            'routes': { active: true, visible: true },
            'route_stops': { active: true, visible: true },
            'clients': { active: true, visible: true },
            'accounts': { active: true, visible: true },
            'contacts': { active: true, visible: true },
            'properties': { active: true, visible: true },
            'billing': { active: true, visible: true },
            'estimates': { active: true, visible: true },
            'invoices': { active: true, visible: true },
            'subscriptions': { active: true, visible: true },
            'payments': { active: true, visible: true },
            'items': { active: true, visible: true },
            'purchase_orders': { active: true, visible: true },
            'audit': { active: false, visible: false },
            'ledgers': { active: false, visible: false },
            'reports': { active: false, visible: false },
            'team': { active: false, visible: false },
            'users': { active: true, visible: true },
            'vehicles': { active: true, visible: true },
            'security': { active: false, visible: false },
            'roles': { active: false, visible: false },
            'permission_sets': { active: false, visible: false },
            'settings': { active: false, visible: false },
        },
        object_permissions: {
            'tasks': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: false },
            'requests': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'jobs': { read: true, create: false, edit: true, delete: false, view_all: false, modify_all: false },
            'routes': { read: false, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'accounts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'contacts': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'properties': { read: true, create: true, edit: true, delete: false, view_all: true, modify_all: false },
            'estimates': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'invoices': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'subscriptions': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'payments': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'items': { read: true, create: true, edit: true, delete: true, view_all: true, modify_all: true },
            'purchase_orders': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'ledgers': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'reports': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'users': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'vehicles': { read: true, create: false, edit: false, delete: false, view_all: true, modify_all: false },
            'roles': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
            'permission_sets': { read: true, create: false, edit: false, delete: false, view_all: false, modify_all: false },
        },
        object_type_permissions: {},
        object_field_permissions: {},
        list_view_permissions: {},
        page_layout_permissions: {}
    },
};

 export default StandardPermissionsData;